body, html {
  background: #000000;
}
@mixin core-component($theme-colors) {
  .coub {
    height: 100vh;
    width: 100vw;
    background: #000000;
    position: absolute;
    z-index: 1000;
    top: 0;
    left: 0;
  }

  .content {
    display: flex;
    background: $body-bg;
    position: relative;
    min-height: 100vh;

    .content-container {
      flex: 1 1 auto;
      margin-top: 95px;
      margin-left: 270px;
      transition: all ease .2s;
      // transition-property: margin, width;

      &.collapsed {
        margin-left: 95px;

        .module-content {
          width: calc(100vw - 95px);
        }
      }
    }
  }

  .core-container {
    overflow: hidden;
    overscroll-behavior: none;
  }

  .global-creation {
    z-index: 115 !important;
    min-width: 540px;
  }
}
