.form-container {
  max-width: 36rem;
}

.cdk-overlay-container {
  z-index: 1800 !important;
}

// margin top
*.mt-4 {
  margin-top: 1.5rem;
}

*.mt-5 {
  margin-top: 2rem;
}

// margin right
*.mr-0 {
  margin-right: 0;
}

*.mr-2 {
  margin-right: .5rem;
}

*.mr-3 {
  margin-right: 1rem;
}

*.mr-4 {
  margin-right: 1.5rem;
}

// margin bottom
*.mb-2 {
  margin-bottom: .5rem;
}

*.mb-3 {
  margin-bottom: 1rem;
}

*.mb-4 {
  margin-bottom: 1.5rem;
}

// margin left
*.ml-0 {
  margin-left: 0;
}

*.ml-2 {
  margin-left: .5rem;
}

*.ml-3 {
  margin-left: 1rem;
}

*.ml-4 {
  margin-left: 1.5rem;
}

*.ml-5 {
  margin-left: 2rem;
}

*.pr-0 {
  padding-right: 0 !important;
}
