.mat-calendar {
  margin-top: 5px;
  box-shadow: 1px 1px 20px 0 rgba(0, 0, 0, 0.2);
  background: #fff;

  .mat-calendar-header {
    background: $primary;
  
    .mat-calendar-controls {
      padding: 5% calc(33% / 7 - 16px);
      padding-top: 5px;
      margin: 0;
      margin-bottom: 10px;

      .mat-calendar-period-button {
        background: darken($primary, 15);
        color: #fff;
      }

      .mat-calendar-previous-button,
      .mat-calendar-next-button {
        background: transparent;
        color: #fff;
      }

      .mat-calendar-previous-button {
        margin-right: 10px;
      }
    }
  }

  .mat-calendar-body-disabled {
    color: $gray-600;
  }

  .mat-calendar-body-cell-content {
    border-color: transparent;
  }

  .mat-calendar-body-selected {
    border-color: $primary;
    color: $primary;
  }

  .mat-calendar-body-today {
    color: $primary;
  }
}