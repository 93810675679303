@mixin module-header-component($theme-colors) {
  .module-header {
    padding: 60px 40px 0 40px;
    width: calc(100% - 270px);
    position: fixed;
    transition: all ease .2s;
    background: $body-bg;
    box-shadow: none;
    z-index: 1002;

    &.collapsed {
      width: calc(100% - 95px);
    }

    &.shadowed {
      box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.3);
    }
  }

  .module-content {
    position: relative;
    margin-top: 155px;
    height: 100%;
    padding: 45px 40px;
    width: calc(100vw - 270px);
  }

  .header-title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h1 {
      margin-bottom: 0;
    }

    .header-actions {
      font-size: 24px;

      i {
        margin-right: 19px;
        cursor: pointer;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .header-menu {
    display: flex;
    justify-content: space-between;
    margin-top: 35px;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      bottom: 10px;
      left: 0;
      height: 1px;
      width: 100%;
      background: #ccc;
    }

    ul {
      display: flex;
      list-style: none;
      font-size: 16px;
    }

    li {
      position: relative;
      margin-right: 28px;

      .nav-link {
        padding: 0 0 10px 0;
        color: #000000;
        letter-spacing: 0.3px;
      }

      &.active {
        &::after {
          content: '';
          position: absolute;
          bottom: 0px;
          left: 0;
          height: 2px;
          width: 100%;
          background: $primary;
          z-index: 101;
        }

        .nav-link {
          color: $primary;
          font-weight: 700;
        }
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
