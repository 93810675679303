i[class^="icon-"]:before, i[class*=" icon-"]:before {
  display: flex;
}

.app-color {
  color: $primary;
}

.icon-info {
  font-size: 16px;
  color: $info;
}

.not-found-icon {
  height: 95px;
  width: 95px;
  border-radius: 50%;
  border: 1px solid $primary;
  display: flex;
  flex-direction: column;
  justify-content: center;

  i {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 42px;
  }
}

.icon-flag {
  margin-right: 8px;
}

.icon {
  cursor: pointer;
  vertical-align: top;

  &.icon-info {
    font-style: normal;
    line-height: 1;
    display: inline-flex;
    flex-shrink: 0;
  }
}

.envelope-icon {
  height: 145px;
  width: 145px;
  margin: 50px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $white;
  border-radius: 50%;

  i.icon-mail {
    font-size: 46px;
    position: relative;

    .badge-primary {
      position: absolute;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 700;
      font-size: 14px;
      font-style: normal;
      top: -8px;
      right: -8px;

      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        animation: pulse 1s ease infinite;
        border-radius: 50%;
        border: 4px double lighten($primary, 20%);
      }

      @keyframes pulse {
        0% {
          transform: scale(1);
          opacity: 1;
        }
        60% {
          transform: scale(1.3);
          opacity: 0.4;
        }
        100% {
          transform: scale(1.4);
          opacity: 0;
        }
      }
    }
  }
}
