@import "styles/core/colors";

.clipboard-card {
  margin: 0px 39px 24px;

  &-title {
    border-radius: 4px 4px 0px 0px;
    padding: 16px;

    color: white;
    background-color: $primary;

    display: flex;
    justify-content: space-between;

    p {
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 0;
    }

    .clipboard-card-title-icons {
      display: flex;
      gap: 16px;
    }

    .icon-clipboard, .icon-download-file {
      font-size: 21px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  &-content {
    background-color: $body-bg;
    padding: 16px;
    border-radius: 4px 0px;
    height: min-content;

    p, ul {
      margin-bottom: 0;

      word-wrap: break-word;
      font-family: 'IBM Plex Mono';
      font-size: 16px;

      overflow-x: scroll;

      /* Chrome, Safari and Opera */
      &::-webkit-scrollbar {
        display: none;
      }
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
    }

    ul li:not(:last-of-type) {
      margin-bottom: 15px;
    }
  }

  &.wizard {
    margin: 0px 80px 40px;
  }

  .cutOff {
    max-height: 63px; 
    text-overflow: ellipsis;
  }
}