.status {
  height: 26px;
  width: 26px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $gray-500;

  &:before {
    content: '';
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background: $gray-500;
  }

  &.creating,
  &.starting,
  &.stopping,
  &.working,
  &.upgrading,
  &.draining,
  &.rebuilding,
  &.runner-starting,
  &.pending, 
  &.finalizing {
    border-color: $gray-900;
    border-style: dashed;

    animation: status-rotate;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

    &:before {
      background-color: $secondary;
    }
  }

  &.runner-debug-mode-running {
    border-color: $warning;
    border-style: dashed;

    animation: status-rotate;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

    &:before {
      background-color: $warning;
    }
  }

  &.runner-running {
    border-color: $success;
    border-style: dashed;

    animation: status-rotate;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

    &:before {
      background-color: $success;
    }
  }

  &.verifing,
  &.pending,
  &.underfunded {
    border-color: $secondary;

    &:before {
      background-color: $secondary;
    }
  }

  &.success,
  &.up,
  &.on,
  &.powered-on,
  &.running,
  &.healthy,
  &.active,
  &.available,
  &.trial,
  &.succeeded,
  &.open,
  &.runner-successful,
  &.alive {
    border-color: $success;
    &:before {
      background-color: $success;
    }
  }

  &.unavailable,
  &.danger,
  &.error,
  &.failed,
  &.runner-failed,
  &.runner-timeout,
  &.marked-for-deletion,
  &.dead {
    border-color: $danger;
    &:before {
      background-color: $danger;
    }
  }

  &.neutral,
  &.in-use,
  &.primary,
  &.scheduled,
  &.used,
  &.runner-scheduled,
  &.available-inactive {
    border-color: $primary;
    &:before {
      background-color: $primary;
    }
  }

  &.suspended,
  &.down,
  &.stopped,
  &.stopped,
  &.off,
  &.unplugged,
  &.un-plugged,
  &.disabled,
  &.closed,
  &.solved,
  &.runner-canceled {
    border-color: $gray-500;
    &:before {
      background-color: $gray-500;
    }
  }

  &.cordoned,
  &.degraded,
  &.warning,
  &.ready-for-finalizing,
  &.runner-debug-mode,
  &.customizing,
  &.finalizing,
  &.maintenance {
    border-color: $warning;
    &:before {
      background-color: $warning;
    }
  }

  &.unhealthy {
    border-color: $warning;

    &:before {
      background-color: $warning;
    }

    &.cluster {
      border-color: $danger;

      &:before {
        background-color: $danger;
      }
    }
  }
}

.mat-column-status {
  div:first-child {
    padding: 0;
  }
}

.status-tooltip {
  &-active,
  &-up,
  &-available,
  &-running,
  &-healthy,
  &-succeeded,
  &-open,
  &-successful,
  &-runner-successful,
  &-runner-running,
  &-alive {
    .tooltip-inner {
      background-color: $success;
    }

    .tooltip-arrow:before {
      border-bottom-color: $success;
    }
  }

  &-neutral,
  &-in-use,
  &-scheduled,
  &-used,
  &-runner-scheduled,
  &-available-inactive {
    .tooltip-inner {
      background-color: $primary;
    }

    .tooltip-arrow:before {
      border-bottom-color: $primary;
    }
  }

  &-creating,
  &-starting,
  &-stopping,
  &-verifing,
  &-working,
  &-upgrading,
  &-draining,
  &-rebuilding,
  &-pending,
  &-runner-starting,
  &-underfunded {
    .tooltip-inner {
      background-color: $secondary;
    }

    .tooltip-arrow:before {
      border-bottom-color: $secondary;
    }
  }

  &-runner-debug-mode, &-runner-debug-mode-running {
    min-width: max-content;
  }

  &-ready-for-finalizing {
    width: 150px;
  }

  &-fixed-width {
    width: fit-content !important;
  }

  &-pending {
    width: 200px;
  }

  &-suspended,
  &-down,
  &-stopped,
  &-unplugged,
  &-closed,
  &-solved,
  &-canceled,
  &-runner-canceled {
    .tooltip-inner {
      background-color: $gray-500;
    }

    .tooltip-arrow:before {
      border-bottom-color: $gray-500;
    }
  }

  &-cordoned,
  &-degraded,
  &-warning,
  &-ready-for-finalizing,
  &-runner-debug-mode,
  &-runner-debug-mode-running,
  &-customizing,
  &-finalizing,
  &-maintenance {
    .tooltip-inner {
      background-color: $warning;
    }

    .tooltip-arrow:before {
      border-bottom-color: $warning;
    }
  }

  &-unhealthy {
    .tooltip-inner {
      background-color: $warning;
    }

    .tooltip-arrow:before {
      border-bottom-color: $warning;
    }

    &.cluster {
      .tooltip-inner {
        background-color: $danger;
      }

      .tooltip-arrow:before {
        border-bottom-color: $danger;
      }
    }
  }

  &-unavailable,
  &-error,
  &-failed,
  &-runner-failed,
  &-runner-timeout,
  &-marked-for-deletion,
  &-dead {
    .tooltip-inner {
      background-color: $danger;
    }

    .tooltip-arrow:before {
      border-bottom-color: $danger;
    }
  }

  // &-stopping {
  //   .tooltip-inner {
  //     background-color: $warning;
  //   }

  //   .tooltip-arrow:before {
  //     border-bottom-color: $warning;
  //   }
  // }
}

.tooltip-default-name {
  .tooltip-inner {
    text-transform: inherit;
  }
}

.tooltip-inner {
  padding: 5px;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 15px;
  min-width: 76px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
}

@keyframes status-rotate {
  0% {
    transform: rotateZ(0deg);
  }

  100% {
    transform: rotateZ(360deg);
  }
}
