// @import "typography";
/*********************************************************************
	 VARIABLES
*********************************************************************/



/********************************************
	 BUTTONS
********************************************/
$btn-padding-x:                  20px !default;
$btn-padding-y:                  11px !default;
$btn-focus-box-shadow:           none !default;
$btn-active-box-shadow:          none !default;

$btn-disabled-opacity:            1 !default;
$btn-link-disabled-color:         $gray-500 !default;
$btn-primary-width:               11.5rem;
$btn-text-transform:              uppercase;
$btn-font-weight:                 500;
$btn-ltr-spacing:                 0.78px;
$primary-btn-font:                $font-family-sans-serif;
$btn-primary-radius:              20px;
$btn-line-height:                 18px;


/********************************************
	 FORMS
********************************************/
$input-padding-x:                1rem !default;
$input-padding-y:                1.2rem !default;
$input-line-height:              1.25 !default;

$input-color:                    #000 !default;
$input-border-color:             #CACACA !default;

$input-border-focus:             #000 !default;
$input-color-placeholder:        #CACACA !default;
$form-group-margin-bottom:       2.4rem;

$form-check-input-gutter:        2.2rem !default;
// $form-check-input-margin-y:             .3rem !default;
// $form-check-input-margin-x:             .25rem !default;


/********************************************
	 ALERTS
********************************************/

$alert-padding-y:                      2rem !default;
$alert-padding-x:                      1.8rem !default;
$alert-margin-bottom:                  2rem !default;
$alert-border-radius:                  0 !default;
$alert-border-width:                   0 !default;
//
// $alert-bg-level:                    -10 !default;
// $alert-border-level:                -9 !default;
// $alert-color-level:                 6 !default;


/********************************************
	 DROPDOWNS
********************************************/
$dropdown-min-width:             19.5rem !default;
$dropdown-padding-y:             1.6rem;
$dropdown-border-width:          0 !default;
$dropdown-divider-bg:            #F4F4F4 !default;
$dropdown-box-shadow:            0 0 4px rgba(0,0,0,0.4) !default;
$dropdown-header-color:          #212121;

$dropdown-link-hover-color:      #000 !default;
$dropdown-link-hover-bg:         transparent !default;

$dropdown-link-active-color:     #000 !default;
$dropdown-link-active-bg:        transparent !default;

$dropdown-item-padding-x:        2.4rem !default;



/********************************************
	 NAVIGATIONS
********************************************/
$nav-link-padding-y:                1rem !default;
$nav-link-padding-x:                1rem !default;

$nav-tabs-border-width:             0 0 2px 0 !default;
$nav-tabs-border-radius:            0 !default;
$nav-tabs-link-active-color:        $secondary !default;
$nav-tabs-link-active-bg:           transparent !default;
$nav-tabs-link-active-border-color: $secondary !default;


/********************************************
	 PAGINATION
********************************************/
$pagination-padding-x:                1.6rem !default;
$pagination-padding-y:                1rem !default;

$pagination-line-height:              1.25 !default;

$pagination-color:                     #333333 !default;
$pagination-bg:                        #F5F5F5 !default;
$pagination-border-width:              0 !default;

$pagination-hover-color:               #fff !default;
$pagination-hover-bg:                  #616161 !default;

$pagination-active-color:              #fff !default;
$pagination-active-bg:                 #616161 !default;

$pagination-disabled-color:            #9E9E9E !default;
$pagination-disabled-bg:               #F5F5F5 !default;



/********************************************
	 TABLES
********************************************/
$table-cell-padding:            1rem !default;
$table-border-color:            #EFF3F4;



/********************************************
	 BADGES
********************************************/
$badge-font-size:             1.2rem !default;
$badge-padding-x:             1em !default;
$badge-padding-y:             .3em !default;


/********************************************
	 MODALS
********************************************/
$modal-inner-padding:               1rem !default;
$modal-content-border-width:        0 !default;
$modal-content-border-radius:       2px !default;
$modal-content-box-shadow-xs:       0 0 4px 0 rgba(0,0,0,0.4) !default;
$modal-content-box-shadow-sm-up:    0 0 4px 0 rgba(0,0,0,0.4) !default;

$modal-header-border-width:         0 !default;
$modal-footer-border-width:         0 !default;
$modal-header-padding:              1rem 1rem 0 1rem !default;
