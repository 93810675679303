@import "core/_colors";
@import "core/_typography";
@import "core/_variables";

@import "core/_fonts";
@import "core/_reset";
@import "core/_layout";

@import "bootstrap/scss/bootstrap";

@import "components/index";

/* Components */

/* Core Module */
@import "app/core/core.component.scss";
@include core-component($theme-colors);

@import "app/wizard/creator/creator.component.scss";
@include creator-component($theme-colors);

@import "app/core/containers/header/header.component.scss";
@include header-component($theme-colors);

@import "app/core/components/header-status/header-status.component.scss";
@include header-status-component($theme-colors);

@import "app/core/components/header-balance/header-balance.component.scss";
@include header-balance-component($theme-colors);

@import "app/core/components/user-menu/user-menu.component.scss";
@include header-user-component($theme-colors);

/* Public Module */
@import "app/public/public.component.scss";
@include public-component($theme-colors);

@import "app/public/components/activate-account/activate-account.component.scss";
@include public-activate-account-component($theme-colors);

@import "app/public/components/password-reset/password-reset.component.scss";
@include public-password-reset-component($theme-colors);

@import "app/public/components/reg-step-third/reg-step-third.component.scss";
@include public-reg-step-third-component($theme-colors);

/* Shared Module */
@import "app/shared/components/module-header/module-header.component.scss";
@include module-header-component($theme-colors);

@import "app/shared/components/dnd/dnd.component.scss";
@include dnd-component($theme-colors);

/// NGX TOASTR MODULE
@import 'ngx-toastr/toastr';
// @import '~ngx-toastr/toastr-bs4-alert';

$flag-icon-css-path: 'node_modules/flag-icon-css/flags';
@import "flag-icon-css/sass/flag-icons";
