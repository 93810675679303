@mixin header-balance-component($theme-colors) {
  .balance {
    display: flex;
    color: #fff;

    .dropdown-toggle {
      &:hover {
        cursor: pointer;
      }

      &:after {
        display: none;
      }
    }

    .balance-text {
      display: flex;
      align-items: center;
      height: 23px;
      font-size: 18px;
    }

    .dropdownBalanceMenu {
      top: 26px;

      &:before {
        content: '';
        position: absolute;
        top: -80px;
        left: 0;
        width: 260px;
        height: 100px;
      }

      .container {
        flex-wrap: wrap;
      }
    }
  }
}
