@import "styles/core/colors";

@mixin public-reg-step-third-component($theme) {
  .two-lines-title {
    max-width: 310px;
  }
}

.reg-form.text-center {
  header {
    justify-content: center;
    text-transform: capitalize;
  }
}

.badge-primary {
  background-color: $primary;
  color: #FFFFFF;
}
