
.overview-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 30px;

  .double-column-cell {
    grid-column: span 2;
  }

  .full-column-cell {
    grid-column: span 3;
  }
}
