.tooltip.show {
  opacity: 1;
}

.tooltip-inner {
  max-width: unset;
}

.tooltip.gray {
  .tooltip-arrow:before {
    border-right-color: #999999;
  }

  .tooltip-inner {
    background: #999999;
    color: white;
  }
}

.customTooltipContent {
  padding: 5px;

  li {
    text-align: left;
    list-style: none;
    margin-bottom: 3px;

    &:last-child {
      margin-bottom: 0;
    }

    a {
      color: #ffffff;
    }
  }
}

.large-tooltip .tooltip-inner {
  width: 150px;
}

.tooltip-side-by-side-item .tooltip-inner{
  max-width: 220px;
  font-weight: 400;
  padding-top: 10px;
  padding-bottom: 10px;
}

.tooltip-support-plan-toggle-active {
  margin-bottom: 4px !important;

  & .tooltip-inner {
    padding: 10px;
    font-weight: 500;
  }
}

.tooltip-support-plan-toggle-large {
  margin-bottom: 4px !important;

  width: 200px;

  & .tooltip-inner {
    display: block;
    padding: 10px;
    font-weight: 500;
  }
}

.tooltip-support-plan {
  width: 360px;

  & .tooltip-inner {
    padding-top: 16px;
    padding-bottom: 16px;
    font-weight: 500;
  }
}

.tooltip-commitment {
  width: 300px;

  .tooltip-inner {
    padding: 6px;
  }
}

.tooltip-info {
  width: 245px;

  .tooltip-inner {
    padding: 10px;
  }
}

.tooltip-full {
  width: auto;

  .tooltip-inner {
    padding: 10px;
  }
}

.tooltip-vpn-type-info {
  width: 250px;
}

.tooltip.primary-dark {
  .tooltip-arrow:before {
    border-top-color: $primary-dark;
  }

  .tooltip-inner {
    background: $primary-dark;
    color: white;
  }
}

.tooltip.primary {
  .tooltip-arrow:before {
    border-top-color: $primary;
  }

  .tooltip-inner {
    background: $primary;
    color: white;
  }
}

.image-tooltip {
  width: 210px;
  padding: 0;
  padding-bottom: 5px;
  .tooltip-inner {
    padding: 10px !important;
  }
}
