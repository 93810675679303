@import "styles/core/_colors.scss";

.side-popup {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 100%;
  height: 100vh;

  box-shadow: 0 0 50px 0 $black;
  background-color: $white;

  &.danger {
    .side-popup-header {
      background-color: $danger !important;
    }

    .side-popup-footer {

      .btn.btn-link {
        color: $danger;
      }

      .btn.btn-primary {
        background-color: $danger !important;
        border-color: $danger !important;
      }
    }
  }

  .side-popup-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    height: 70px;
    min-height: 70px;
    padding: 0 30px;

    background-color: $primary;
    color: $white;

    h1 {
      font-size: 16px;
      letter-spacing: 0.5px;
      line-height: 25px;
    }

    .side-popup-close {
      cursor: pointer;
    }
  }

  .side-popup-content {
    flex: auto;
    overflow-y: scroll !important;
    padding: 20px 60px;

    overflow-y: auto;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (min-height: 930px) {
      overflow-y: visible;
    }

    .vertical-center {
      margin-top: auto;
      margin-bottom: auto;
    }

    &.form-content {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      overflow-x: hidden;
      overflow-y: auto;
    }

    &.no-center {
      justify-content: flex-start;
      align-items: unset;
    }

    &.no-padding {
      position: relative;
      padding: 0;
    }

    .form-wrapper {
      padding: 0 40px;

      &.centered {
        margin-top: auto;
        margin-bottom: auto;
      }
    }

    .hint {
      margin-top: auto;
      margin-bottom: 0;
    }

    // Use margins for centering instead of flexbox
    // fixes scrolling issue
    &.margin-center {
      justify-content: unset;
      margin-top: auto;
      margin-bottom: auto;
    }

    &.side-popup-info {
      background-color: rgba(241,205,39,0.1);
      padding: 60px;

      .title {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        h3 {
          font-size: 16px;
          font-weight: 700;
          padding-left: 10px;
          margin: 0;
        }
      }

      p {
        font-size: 16px;
      }

    }
  }

  .info-message {
    background-color: rgba(39,110,241,0.1);
    padding: 60px;
    margin: 40px 0;

    h3 {
      font-size: 16px;
      font-weight: 500;
    }

    p {
      font-size: 16px;
    }

    .message-title {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      h3 {
        padding-left: 10px;
        margin: 0;
      }
    }
  }

  .side-popup-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    height: 80px;
    min-height: 80px;
    padding: 0 30px;

    border-top: 2px solid #EEEEEE;
    background-color: white;

    .btn {
      max-height: 40px;
      min-width: 100px;
      padding: 1.0rem 2rem
    }

    .btn-link:hover {
      color: inherit;
    }

    p {
      margin: 0;
    }

    .wizard-total-amount {
      margin-right: 20px;
    }
  }

  .footer {
    display: flex;
  }

  .modal-body {
    .wizard-section {
      width: auto;
    }
  }

  .modal-footer {
    background-color: #fff;
  }
}
