/*********************************************************************
   COLORS
*********************************************************************/


/********************************************
   BOOTSTRAP OVERRIDE DEFAULT GRAY VARIABLES
********************************************/
$gray-900:     #212121 !default;
$gray-600:     #6F6F6F !default;
$gray-500:     #9E9E9E !default;
$gray-300:     #d8d8d8 !default;
$gray-200:     #eeeeee !default;
$gray-100:     #f8f9fa !default;
$gray-50:      #f5f7f7 !default;

/********************************************
    BOOTSTRAP OVERRIDE DEFAULT VARIABLES
********************************************/
$primary:             #276EF1 !default;
$secondary:           #8317B9 !default;
$success:             #2B8C00 !default;
$info:                $primary !default;
$selected:            #DFEAFD !default;
$danger:              #ff4136 !default;
$selected-danger:     rgba(255, 0, 0, 0.1) !default;
$warning:             #F89406 !default;
$selected-warning:     rgba(241,205,39,0.1) !default;
$gray:                $gray-600 !default;
$white:               #FFFFFF !default;
$body-bg:             #EBEFF0 !default;
$tag:                 #174BAC !default;
// $warning:       $yellow !default;
// $danger:        $red !default;
// $light:         $gray-100 !default;

/********************************************
   DASHBOARD
********************************************/
$dashboard-background: #1C1C1C !default;
$dashboard-for-you-base-widget: #262626 !default;
$dashboard-for-you-base-widget-empty-text: #666666 !default;
$dashboard-for-you-divider: #333333 !default;
$dashboard-featured-card-background: #00000066 !default;
$dashboard-featured-card-background-hover: #276ef166 !default;
$dashboard-featured-card-button: #00000000 !default;
$dashboard-featured-card-button-hover: $primary !default;
$dashboard-developer-center-header: #999999 !default;

/********************************************
   BUTTON COLORS
********************************************/
$primary-dark: #164BAC;
$primary-opaque: #276EF119;

/********************************************
   NOTIFICATION COLORS
********************************************/
// Needed?
$color-notification-error: $danger;
$color-notification-error2: #4A4A4A;

$color-external-link: $danger;

// New
$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "danger": $danger,
  "gray": $gray,
  "gray-dark": $gray-900
);

$colors: $theme-colors !default;
