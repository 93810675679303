@use '../../../../node_modules/@angular/material/index' as mat;

@mixin creator-component($theme-colors) {
  @include mat.core();

  .mat-mdc-tab-group {
    .mat-mdc-tab-nav-bar,
    .mat-mdc-tab-header {
      margin: 50px auto 100px;
      border-bottom: $gray-400 1px solid;
    }

    .mat-ink-bar {
      background: $primary;
    }

    .mdc-tab {
      margin-right: 28px;
      font-family: $font-family-sans-serif !important;
      padding: 0 0 10px 0;
      color: #000000;
      font-size: 16px;
      height: auto;
      min-width: auto;
      font-weight: 400;
      opacity: 1;

      &--active {
        color: $primary;
        font-weight: 700;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .global-creator .side-popup-content {
    overflow-y: auto;
    overflow-x: hidden;
    justify-content: unset;
  }

  .side-popup-content-wrapper {
    height: calc(100vh - 150px);
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .btn.btn-primary.btn-instance {
    display: flex;
    justify-content: space-between !important;
    align-items: center;

    width: 95%;
    min-height: 80px;

    border-radius: 50px;
    
    font-family: "Avenir Next New W01 Regular";
    font-weight: 400;
    font-size: 22px;
    text-transform: none;
    
    padding: 0;
    margin-left: auto;
    margin-right: auto;

    transition: box-shadow ease .3s;
    box-shadow: 0 20px 20px -20px $primary;

    &:hover {
      box-shadow: none;
    }

    [class*="icon-"] {
      font-size: 32px;
    }

    .d-flex {
      align-items: center;
    }

    .icon-server,
    .icon-mac-mini,
    .icon-ci-integration {
      margin-left: 37px;
      margin-right: 16px;
    }

    .icon-add {
      position: relative;
      height: 80px;
      width: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 32px !important;

      &::after {
        content: '';
        height: 100%;
        width: 1px;
        background: #fff;
        left: 0px;
        top: 0;
        position: absolute;
        opacity: 0.3;
      }
    }
  }

  .creator-list {
    list-style: none;
    padding: 60px 40px;
    min-height: 450px;

    .creator-item {
      height: 64px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid $gray-300;
      transition: background-color ease .3s;
      background-color: transparent;
      padding: 20px;
      font-size: 16px;

      &:first-child {
        border-top: 1px solid $gray-300;
      }

      &:hover {
        background-color: $gray-50;
        cursor: pointer;
      }

      div {
        align-items: center;
      }

      [class*="icon-"] {
        font-size: 24px;
      }

      .icon-add {
        color: $primary;
      }
    }
  }
}
