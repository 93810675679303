@use "sass:math";

/********************************************
   FORM BOOTSTRAP RESET
********************************************/
input {
  font-family: $font-family-sans-serif;
  font-size: $fontSize14;
}

.form-group {
  position: relative;
  margin-bottom: 2.4rem;

  label {
    font-weight: 700;
    display: flex;

    span {
      color: $primary;
      margin-left: 2px;
    }

    i {
      color: $primary;
      margin-left: auto;
      font-size: 17px;
    }
  }

  .input-container {
    position: relative;

    .input-file:read-only {
      background-color: #fff;

      &:hover {
        cursor: pointer;
      }
    }

    .file-chooser {
      position: absolute;
      top: 0;
      right: 20px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      a {
        cursor: pointer;
        color: $primary;
        font-weight: 700;
        margin: 1.2rem 0;
      }
    }
  }
}

input,
select,
textarea {
  &.form-control {
    background-color: #fff;

    &:focus {
      background-color: #fff;
      box-shadow: none;
      border-color: #000000;
      border-bottom: 3px solid $info;
      transition: border-bottom-color 1s ease;
    }

    &::placeholder {
      opacity: 0.6;
    }

    &.ng-invalid.ng-touched {
      border-color: $danger;
    }
  }
}

.form-group-label {
  display: flex;
  justify-content: space-between;

  .icon {
    color: $primary;
    font-size: 16px;
  }
}

.alert {
  &.alert-danger {
    border: none;
    background-color: transparent;
    font-size: 1.2rem;
    color: $danger;
    padding: 0.2rem 0px;
  }
}

.ng-select {
  &.ng-select-opened {
    >.ng-select-container {
      background: #fff;
      border-color: #CFCFCF !important;

      appearance: none;
      background-image: url("../../assets/img/icon-select-top.svg") !important;
      background-repeat: no-repeat;
      background-position: calc(100% - 10px) center;
      background-size: 10px auto;

      &:hover {
        box-shadow: none;
      }

      .ng-arrow {
        &:hover {}
      }
    }

    &.ng-select-bottom {
      >.ng-select-container {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }
    }

    &.ng-select-top {
      >.ng-select-container {
        border-top-right-radius: 0;
        border-top-left-radius: 0;
      }
    }
  }

  &.ng-select-disabled {
    >.ng-select-container {
      background-color: #f9f9f9;
    }
  }

  .ng-has-value .ng-placeholder {
    display: none;
  }


  .ng-select-container {
    background-color: #fff;
    border-radius: 2px;
    border: 1px solid #cacaca;
    min-height: 45px;
    align-items: center;

    appearance: none;
    background-image: url("../../assets/img/icon-select.svg");
    background-repeat: no-repeat;
    background-position: calc(100% - 10px) center;
    background-size: 10px auto;

    .ng-value-container {
      align-items: center;
      padding-left: 10px;

      .ng-placeholder {
        color: #656565;
      }
    }
  }

  &.ng-select-single {
    &.ng-dirty {
      .ng-has-value .ng-placeholder {
        display: none;
      }
    }

    .ng-select-container {
      height: 36px;

      .ng-value-container {
        position: relative;
        height: 21px;

        .ng-input {
          top: 0;
          left: 0;
          padding: 0 10px;
        }
      }
    }
  }

  &.ng-select-multiple {
    .ng-has-value .ng-placeholder {
      display: none;
    }

    &.ng-select-disabled {
      >.ng-select-container .ng-value-container .ng-value {
        background-color: #f9f9f9;
        border: 1px solid #e3e3e3;

        .ng-value-label {
          padding: 0 5px;
        }
      }
    }

    .ng-select-container {
      .ng-value-container {
        padding-top: 5px;
        padding-left: 7px;

        .ng-value {
          font-size: 1em;
          margin-bottom: 5px;
          padding: 6px;
          background-color: $selected;
          border-radius: 2px;
          margin-right: 5px;

          &.ng-value-disabled {
            background-color: #f9f9f9;
            border: 1px solid #e3e3e3;

            .ng-value-label {
              padding-left: 5px;
            }
          }

          .ng-value-label {
            display: inline-block;
            padding: 0 5px 0 5px;
          }

          .ng-value-icon {
            display: inline-block;
            padding: 0 5px;

            &.left {}

            &.right {}
          }
        }

        .ng-input {
          padding: 0 0 3px 3px;
        }

        .ng-placeholder {
          top: 12px;
          padding-left: 10px;
        }
      }
    }
  }

  .ng-clear-wrapper {
    display: flex;
    align-items: center;
    padding-top: 1px;
    color: #999;

    .ng-clear {
      &:hover {
        color: #D0021B;
      }
    }
  }

  .ng-spinner-zone {
    padding: 5px 5px 0 0;
  }

  .ng-arrow-wrapper {
    width: 25px;
    padding-right: 5px;

    &:hover {
      .ng-arrow {
        //border-top-color: #666;
      }
    }

    .ng-arrow {
      //border-color: #999 transparent transparent;
      //border-style: solid;
      //border-width: 5px 5px 2.5px;
    }
  }

  &.form-recharge, &.form-concurrency {
    .ng-dropdown-panel .ng-dropdown-panel-items {
      max-height: 325px !important;
    }
  }
}

.ng-dropdown-panel {
  background: #fff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.4);
  left: 0;
  border-radius: 3px;
  overflow: hidden;

  .ng-option-label {
    white-space: normal;
  }

  &.ng-select-bottom {
    top: 100%;
    margin-top: 5px;

    .ng-dropdown-panel-items {
      .ng-option {
        &:last-child {
          border-bottom-right-radius: 2px;
          border-bottom-left-radius: 2px;
        }
      }
    }
  }

  &.ng-select-top {
    bottom: 100%;
    border-top-right-radius: 2px;
    border-top-left-radius: 2px;
    border-bottom-color: $gray-100;
    margin-bottom: -1px;

    .ng-dropdown-panel-items {
      .ng-option {
        &:first-child {
          border-top-right-radius: 2px;
          border-top-left-radius: 2px;
        }
      }
    }
  }

  .ng-dropdown-header {
    border-bottom: 1px solid $gray-200;
    padding: 5px 7px;
  }

  .ng-dropdown-footer {
    border-top: 1px solid $gray-200;
    padding: 5px 7px;
  }

  .ng-dropdown-panel-items {
    margin-bottom: 1px;

    .ng-option-disabled,
    .ng-option {
      user-select: none;
      padding: 8px 10px;
      color: rgba(0, 0, 0, 0.54);
      cursor: pointer;

      &.ng-option-disabled {
        cursor: default;
        background-color: #f9f9f9;
      }

      &.ng-option-marked {
        color: $primary; // Selected option
      }

      &.ng-option-selected {
        color: $primary; // Option is hovered
      }
    }

    .ng-option {
      background-color: #fff;
      color: rgba(0, 0, 0, .87);
      padding: 8px 10px;
      margin-left: 1px;

      &.ng-option-selected {
        color: $primary;
      }

      &.ng-option-marked {
        color: $primary;
      }

      &.ng-option-disabled {
        color: $gray-600;
      }

      &.ng-option-child {
        padding-left: 22px;
      }

      .ng-tag-label {
        font-size: 80%;
        font-weight: 400;
        padding-right: 5px;
      }
    }
  }
}

.require-message {
  margin-top: 4px;

  span {
    font-weight: 400;
  }
}

@mixin size($width, $height, $offset) {
  $indicator-size: $height - 2 * $offset;

  width: $width;
  height: $height;

  border-radius: math.div($height, 2);

  .toggle-indicator {
    width: $indicator-size;
    height: $indicator-size;
    border-radius: math.div($indicator-size, 2);
    margin: $offset;
  }

  &.toggled .toggle-indicator {
    margin-left: $width - $indicator-size - $offset;
  }
}

.toggle {
  display: flex;
  align-items: center;
  background-color: $gray;
  cursor: pointer;

  @include size(50px, 26px, 5px);

  .toggle-indicator {
    background-color: white;
    transition: margin-left 0.2s ease;
  }

  &.toggled {
    background-color: $primary;
  }

  &.disabled {
    background-color: $gray-300;
    cursor: default;
  }

  &.always-colored {
    background-color: $primary;
  }

  &.small {
    @include size(34px, 18px, 3px);
  }
}

.code-input-wrapper {
  .form-group .row {
    flex-wrap: nowrap;
    margin: 0;

    .form-control {
      max-width: 30px;
      margin-right: 10px;
      padding: 1rem;
    }
  }
}

*.form-control {
  background-color: #fff;
}

.form-select {
  padding: 0;
  -moz-padding-start: 0;
  background-color: transparent;
  background-image: none;
  background-repeat: no-repeat;
  border: none;
  border-radius: 0;
}

label {
  margin-bottom: .5rem;
}

.mat-mdc-chip-set {
  display: inline-flex !important;
  padding-left: 5px;
  width: 100%;
}

.mat-mdc-chip {
  padding: 6px;
  background-color: $selected !important;
  border-radius: 2px !important;

  margin-top: 0px !important;
  margin-bottom: 0px !important;
  margin-right: 5px !important;

  font-weight: normal !important;

  &.cdk-focused {
    opacity: 60%;
  }
}

input.mat-mdc-chip-input {
  padding: 6px;
  margin: 0 !important;
}
