body {
  background: #ffffff;
}

@mixin public-component($theme) {
  .page--login {
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    position: relative;


    @if variable_exists(bg-login) {
      > * {
        z-index: 1;
      }

      &:after {
        content: '';
        position: absolute;
        right: 0;
        bottom: 0;

        width: 75%;
        height: 75%;

        background-size: cover;
        background-repeat: no-repeat;
        background-image: url($bg-login);
      }
    }
  }

  .form-container {
    max-width: 36rem;
  }

  .login-form,
  .reg-form {
    width: 370px;
    max-width: 370px;

    // @todo responsive break-points

    header {
      justify-content: center;
      margin-bottom: 60px;
      display: flex;
      flex-wrap: wrap;
      width: 100%;
    }

    .request-wrapper {
      p {
        font-size: 16px;
        line-height: 21px;
      }
    }

    .button-wrapper {
      margin-top: 60px;
      display: flex;
      justify-content: flex-start;

      .info {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .btn {
          padding: 0;

          &.btn-link {
            color: $gray;

            &:hover {
              color: $info;
            }
          }
        }
      }
    }
  }
}
