/* toast styles */
.toast-title {
  padding-bottom: 3px;
}

.toast-message {
  word-wrap: break-word;
}

.toast-message a,
.toast-message label {
  color: #FFFFFF;
}

.toast-message a:hover {
  color: #CCCCCC;
  text-decoration: none;
}

.toast {
  //background-color: #030303;
  //min-height: 115px !important;
  width: 300px !important;
  pointer-events: auto;
  border-radius: 2px;
  font-size: 14px; // TODO
  text-align: left !important;
}

.toast-success {
  background-color: $success !important;
}

.toast-error {
  background-color: $danger !important;
}

.toast-info {
  background-color: $primary !important;
}

.toast-warning {
  background-color: $warning !important;
}

.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: $primary;
  opacity: 0.4;
}

.toast-close-button:hover,
.toast-close-button:focus {
  //color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
}

.toast-info {
  background-image: none !important;
}

.toast-stopped {
  background-color: #999999;
}

.toast-error {
  background-image: none !important;
}

.toast-success {
  background-image: none !important;
}

.toast-warning {
  background-image: none !important;
}

.toast-container .toast,
.toast-container .ngx-toastr {
  font-family: $font-family-sans-serif;
  position: relative;
  display: block;
  opacity: 1;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 16px 18px !important;
  width: 300px;
  border-radius: 5px;
  border: 0;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 24px;
  box-shadow: none !important;
  color: #FFFFFF;
}

.toast-message-container {
  display: flex;
  justify-content: space-between;
  color: #FFFFFF;

  .close-btn {
    display: flex;
    justify-content: center;
    align-items: center;

    a {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      color: inherit;

      &:hover {
        text-decoration: none;
      }
    }

    i {
      font-size: 21px;
    }
  }
}

.toast-container .toast:hover {
  box-shadow: none !important;
  opacity: 1;
}

.toast-action-gif {
  display: block;
  margin: 0 0 6px;

  .toastr-container {
    width: 300px;
    box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    overflow: hidden;
  }

  .close-btn {
    display: none;
  }

  .toast-message img {
    width: 300px;
  }
}
