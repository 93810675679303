@mixin dnd-component($theme-colors) {
  .filezone-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    min-height: 110px;
    width: 100%;
    color: $primary !important;

    .centered, .centered a {
      color: $primary;
      font-weight: 700;
      letter-spacing: 0.3px;
    }

    .centered a {
      display: inline-block;
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .file {
    max-width: 85px;
    min-width: 85px;
    max-height: 110px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px;
    margin: 5px;
    overflow: hidden;
    position: relative;

    &:hover {
      .over {
        display: flex;
        opacity: 1;
        cursor: pointer;
      }
    }

    .over {
      transition: opacity linear 0.2s;
      height: 100%;
      width: 100%;
      background: rgba(#ffffff, .8);
      opacity: 0;
      display: none;
      position: absolute;
      justify-content: center;
      align-items: center;
      font-size: 36px;
      top: 0;
      left: 0;
    }

    .name {
      max-width: 85px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .icon {
      font-size: 5rem;
      height: 63px;
    }

    .status {
      height: 100%;
      width: 100%;
    }

    &.file-add {
      border: 1px dashed #8114FF;
      border-radius: 5px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .add-file-link {
    position: absolute;
    height: 100%;
    top: 0;
    right: 20px;
    color: $primary;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    p {
      cursor: pointer;
      margin: 1.2rem 0;
      font-weight: 700;
      z-index: 2;
    }
  }

  .dropzone {
    border: 1px dashed $primary;

    &.details {
      min-height: 120px;
      border-top: 1px solid #CBCBCB;
      border-left: none;
      border-right: none;
      border-bottom: none;
    }
  }

  .dropzone-info {
    font-size: 12px;
    line-height: 15px;
    padding-top: 10px;
  }

  .file-list-name {
    display: flex;
    align-items: center;

    & i {
      margin-right: 7px;
    }
  }

  .dropzone-list {
    ul {
      list-style-type: none;
      margin-top: 10px;
      padding-left: 0px;
    }

    li {
      background-color: rgba(39, 110, 241, 0.1);
      color: $primary;
      border-radius: 2px;
      min-height: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
      margin: 5px 0;
    }
  }
}


