/*********************************************************************
	 TYPOGRAPHY
*********************************************************************/
$font-family-sans-serif           : "Avenir Next New W01 Regular", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !default;
$font-family-sans-serif-bold      : "Avenir Next LT W01 Bold", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !default;
$font-size-base                   : 1.4rem !default; // Assumes the browser default has been reset, typically `10px` for simplicity
$font-size-lg                     : 1.75rem !default;
$font-size-sm                     : 1.2rem !default;
$font-size-xs                     : 1rem !default;
$font-weight-normal               : 400 !default;

$headings-font-weight             : 400 !default;
$small-font-size                  : 1.2rem !default;

/*@todo set fontsizes as variables*/
$fontSize8                        : 0.8rem;
$fontSize10                       : 1rem;
$fontSize12                       : 1.2rem;
$fontSize14                       : 1.4rem;
$fontSize16                       : 1.6rem;
$fontSize26                       : 2.6rem;
$fontSize28                       : 2.8rem;
$fontSize32                       : 3.2rem;
$fontSize36                       : 3.6rem;
$fontSize41                       : 4.1rem;
$fontSize55                       : 5.5rem;
$fontSize90                       : 9rem;