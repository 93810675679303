@mixin header-user-component($theme-colors) {
  .user-avatar {
    height: 26px;
    width: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &:hover {
      cursor: pointer;
    }

    &:after {
      display: none;
    }

    img {
      height: 100%;
      width: 100%;
      border-radius: 50%;
    }
  }

  .dropdown-menu {
    z-index: 2147483646;

    .user-info {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;

      .user-name {
        width: 100%;
        font-size: 16px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .user-org {
        color: $gray-600;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .container {
      flex-wrap: wrap;
    }

    .logout-link {
      display: flex;
      align-items: center;
      border: none;

      &:hover {
        color: $danger;
      }

      i {
        font-size: 16px;
        transform: rotate(-90deg);
        margin-right: 5px;
      }
    }

    .org-label {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .label-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        color: $primary;

        &:hover {
          cursor: pointer;
          color: darken($primary, 5%);
        }
      }
    }
  }
}
