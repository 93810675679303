@mixin public-activate-account-component($theme) {
  .verify {
    .request-wrapper {
      .form-group .row {
        flex-wrap: nowrap;
        margin: 0;

        .form-control {
          max-width: 30px;
          margin-right: 10px;
          padding: 1rem;
        }
      }

    }

    .btn-link {
      margin: 0;
      padding: 1.2rem 2rem;
    }

    .button-wrapper {
      justify-content: space-between;
    }
  }

  .reg-form {
    header {
      h1 {
        text-transform: none;
      }
    }
  }

  .request-wrapper {
    p {
      font-size: 16px;
      line-height: 21px;
    }
  }

  .phone-tooltip {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .onfido-sdk-ui-Modal-inner {
    border: 0 !important;
    background-color: transparent !important;

    .onfido-sdk-ui-PageTitle-title,
    .onfido-sdk-ui-PageTitle-subTitle,
    .onfido-sdk-ui-Welcome-text {
      font-family: $font-family-sans-serif !important;
      font-weight: 400;
    }

    .onfido-sdk-ui-PageTitle-title {
      .onfido-sdk-ui-PageTitle-titleSpan {
        font-size: 3.5rem;
        line-height: 1.2;
      }
    }

    .onfido-sdk-ui-Button-button {
      @extend .btn;
      margin: 0;
      height: 40px;
      width: auto !important;

      .onfido-sdk-ui-Button-button-text {
        font-size: 14px;
        font-family: $font-family-sans-serif !important;
        font-weight: 500;
      }

      &.onfido-sdk-ui-Button-button-centered {
        margin: 0 auto;
      }

      &.onfido-sdk-ui-Button-button-primary,
      &.onfido-sdk-ui-Button-button-small {
        @extend .btn-primary;

        &:hover {
          background-color: darken($primary, 7.5%) !important;
        }
      }
    }

    .onfido-sdk-ui-DocumentSelector-option {
      .onfido-sdk-ui-DocumentSelector-optionHoverDesktop {
        &:hover {
          box-shadow: 0px 0px 0px 2px $primary !important;
        }
      }
    }

    .onfido-sdk-ui-CustomFileInput-container {
      color: $gray-600;
      margin-top: 30px;
      display: flex;
    }

    .onfido-sdk-ui-crossDevice-CrossDeviceLink-numberInputSection {
      display: flex;

      .onfido-sdk-ui-crossDevice-CrossDeviceLink-btn {
        border-radius: 0 5px 5px 0;
        height: 100%;
        padding: 0 10px;
      }
    }
  }

  .onfido-sdk-ui-Theme-modalOverlay {
    z-index: 100;
  }

  .onfido-sdk-ui-Theme-link {
    color: $primary !important;
    text-decoration: none;
    border-bottom: none !important;

    &:hover, &:active {
      background-color: transparent !important;
      color: $primary !important;
      text-decoration: none;
    }
  }
}


.onfido-sdk-ui-Modal-inner {
  border: 0 !important;
}

.country-group {
  max-width: 150px;

  ::ng-deep .ng-dropdown-panel {
    min-width: 300px;
  }
}

.country-item {
  display: flex;

  .flag-icon {
    min-width: 28px;
    margin-right: 10px;
  }
}
