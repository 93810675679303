.wizard-close {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  float: none;
  opacity: 1;
  padding: 0 !important;
  margin: 0 !important;
  height: 24px;
  width: 24px;

  &:hover {
    cursor: pointer;
  }
}

.modal-open .modal {
  z-index: 1650;
  display: flex !important;
  align-items: center;
  justify-content: center;

  overflow: hidden;
}

.confirmModal > .modal-dialog {
  max-width: 525px;
  min-width: 400px;
}

.modal-dialog {
  width: 100%;

  &.fullscreen {
    min-width: 100vw;
    min-height: 100vh;
  }
}

.modal-content {
  border-radius: 0;
  font-size: 16px;

  .close {
    flex-basis: 25%;
  }

  span {
    font-weight: 500;
  }
}

.modal-header {
  padding: 1.1em 2rem;
  background: $primary;
  color: #fff;
  display: flex;
  align-items: center;
  line-height: 1;
  border-radius: 0;
  font-size: 16px;
}

.modal-body {
  padding: 30px;
}

.modal-title {
  display: flex;
  flex-grow: 1;
  flex-basis: 70%;

  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 25px;
}

.modal-footer {
  height: 80px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid $gray-200;

  .btn-link:hover {
    color: inherit;
  }

  .btn-next-step {
    padding: 1rem 2rem;
    max-height: 40px;
  }
}

.modal-backdrop {
  z-index: 1650 !important;
  &.show {
    opacity: 0.8 !important;
  }
}

.volume-dialog {
  .modal-footer {
    &.custom-footer {
      justify-content: space-between;

      .right-content {
        width: 100%;
        justify-content: space-between;
      }
    }
  }
}

.modal-critical {
  .modal-footer .submit {
    background-color: $danger;
  }

  .modal-footer .submit[disabled] {
    background-color: $gray-500;
  }

  .modal-footer .btn-link {
    color: $danger;
  }

  .form-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 30px;

    &:last-child {
      margin-bottom: 0;
    }


    label {
      font-weight: 400;
      flex-grow: 1;
      margin-left: 10px;
    }
  }
}

.break-word {
  word-break: break-all;
}
