/*********************************************************************
   FONT DEFINITION
*********************************************************************/

/************************************************************
   Avenir Next
*************************************************************/

/********************************************
   Avenir Next Font-weight -> 250
********************************************/

@font-face{
    font-family:"Avenir Next New W01 Regular";
    src: url("/assets/fonts/avenir-next/AvenirNextLTPro-UltLt.ttf") format("truetype");
    font-weight: 250;
    font-style: normal;
}

/********************************************
   Avenir Next Font-weight -> 275
********************************************/

@font-face{
    font-family:"Avenir Next New W01 Regular";
    src: url("/assets/fonts/avenir-next/AvenirNextLTPro-Thin.ttf") format("truetype");
    font-weight: 275;
    font-style: normal;
}

/********************************************
   Avenir Next Font-weight -> 300
********************************************/

@font-face{
    font-family:"Avenir Next New W01 Regular";
    src: url("/assets/fonts/avenir-next/AvenirNextLTPro-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
}


/********************************************
   Avenir Next Font-weight -> 400
********************************************/

@font-face{
   font-family:"Avenir Next New W01 Regular";
   src: url("/assets/fonts/avenir-next/AvenirNextLTPro-Regular.ttf") format("truetype");
   font-weight: 400;
   font-style: normal;
}

/********************************************
   Avenir Next Font-weight -> 500
********************************************/

@font-face{
   font-family:"Avenir Next New W01 Regular";
   src: url("/assets/fonts/avenir-next/AvenirNextLTPro-Medium.ttf") format("truetype");
   font-weight: 500;
   font-style: normal;
}

/********************************************
   Avenir Next Font-weight -> 700
********************************************/

@font-face{
    font-family:"Avenir Next New W01 Regular";
    src: url("/assets/fonts/avenir-next/AvenirNextLTPro-Demi.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}

/********************************************
   Avenir Next Font-weight -> 800
********************************************/

@font-face{
    font-family:"Avenir Next New W01 Regular";
    src: url("/assets/fonts/avenir-next/AvenirNextLTPro-Bold.ttf") format("truetype");
    font-weight: 800;
    font-style: normal;
}

/********************************************
   Avenir Next Font-weight -> 900
********************************************/

@font-face{
    font-family:"Avenir Next New W01 Regular";
    src: url("/assets/fonts/avenir-next/AvenirNextLTPro-Heavy.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
}

/************************************************************
   IBM Plex Mono
*************************************************************/

@font-face {
   font-family: 'IBM Plex Mono';
   src: url('/assets/fonts/ibm-plex-mono/ibm-plex-mono-v19-latin-regular.ttf') format('truetype');
   font-weight: 400;
   font-style: normal;
 }

/************************************************************
   Material Icons
*************************************************************/

@import 'https://fonts.googleapis.com/icon?family=Material+Icons';
