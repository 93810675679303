
mat-table {
  .ellipsis {
    overflow-x: hidden;
    text-overflow: ellipsis;
    width: 100%;
    white-space: nowrap;
  }

  mat-header-row {
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    font-weight: 700;

    mat-header-cell {
      border-bottom-style: none !important;

      &:first-child {
        border-top-left-radius: 5px;
      }

      &:last-child {
        border-top-right-radius: 5px;
      }
    }
  }

  mat-row:last-child {
    border-bottom-style: none !important;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;

    mat-cell:first-child {
      border-bottom-left-radius: 5px;
    }

    mat-cell:last-child {
      border-bottom-right-radius: 5px;
    }
  }

  mat-cell > * {
    margin: 18px 0;

    &.expansion-panel-row {
      margin: 0;
      padding: 18px 0;
    }

    &.value {
      // @todo overflow (and overflow-y) always would be hidden, check other solutions
      // (founded ones not works in browsers, mb -webkit-box-orient: vertical; but it's not good for tooltip)
      // @link https://developer.mozilla.org/en-US/docs/Web/CSS/text-overflow

      // overflow-x: hidden;
      // text-overflow: ellipsis;
      // width: 100%;
      // white-space: nowrap;
    }
  }

  mat-cell,
  mat-header-cell {
    overflow: unset !important;
    border-bottom-style: none !important;

    &:first-of-type {
      padding-left: 20px;
    }

    &.disabled {
      color: $gray-500;
    }
  }

  .mat-mdc-header-row,
  .mat-mdc-row {
    a {
      text-decoration: none;
      font-weight: 700;
    }
  }
  // Table Header Row
  .mat-mdc-header-row {
    border-bottom: none !important;
    height: 50px !important;
    font-size: 12px;
    min-height: initial;
    align-items: center;
    background-color: $selected;
  }

  .mat-mdc-cell {
    background: none;
  }

  // Table Content Rows
  .mat-mdc-row {
    background: #ffffff;
    box-shadow: none;
    transition: all ease-in-out 0.2s;
    position: relative;

    &.dummy::before {
      content: '';
      width: 10px;
      height: 10px;
      background: $primary;
      position: absolute;
      left: -25px;
      border-radius: 50%;
    }
  }

  .mat-row-link {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    cursor: pointer;
    margin: 0;
  }

  &.instances {
    .mat-mdc-row {
      border-radius: 5px;

      &:hover {
        box-shadow: 0px 25px 25px -20px #D8D8D8;
        background-color: #ffffff !important;
      }

      .mat-mdc-cell:first-of-type + :not(.cdk-column-custom), .mat-mdc-cell:last-of-type {
        border-radius: 5px;
      }
    }

    .mat-row-link {
      top: -7px;
      padding: 7px 0;
      box-sizing: content-box;
    }

    &:not(.placeholders) {
      .mat-mdc-row:hover {
        &:after {
          border-radius: 5px 0 0px 5px;
        }
      }
    }
  }

  &:not(.instances) {
    .mat-mdc-row {
      border-bottom: 1px solid $gray-300;

      &:not(.expandable-detail-row) {
        &:hover {
          background-color: $gray-100;
        }
      }
    }
  }

  &:not(.placeholders) {
    .mat-mdc-row {
      &:after {
        background: #ffffff;
        width: 0px;
        transition: background linear 0.2s;
      }

      &:hover {
        background-color: #FFFFFF;
        
        &:not(.expandable-detail-row) {
          &:after {
            content: '';
            position: absolute;
            height: 100%;
            width: 4px;
            background: $primary;
            left: 0;
            top: 0;
          }
        }
      }
    }
  }

  // Rewrite Bootstrap grid
  .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12 {
    position: relative;
    padding-right: 15px;
    padding-left: 15px;
  }

  .actions {
    justify-content: flex-end;
  }
}

// For instances tables
.instances-table {
  .mat-mdc-row {
    margin-bottom: 20px;
  }

  .mat-mdc-header-row {
    background-color: transparent;

    .mdc-data-table__header-cell {
      color: $gray-600;

      // If a column is sorted
      .mat-sort-header-sorted {
        .mat-sort-header-content {
          color: $gray-900;
        } 
      }
    }
  }
}

.column-cell {
  flex-direction: column;
  align-items: self-start;
  justify-content: center;

  .column-cell-item {
    margin: 0;
  }
}

// Table footer
.entities-table-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;

  .pagination {
    margin: 0;

    .page-item {
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      margin: 0 5px;

      &:first-child {
        width: 16px;

        a {
          padding: 1rem 1.6rem 1rem 0;
        }
      }

      &.active {
        a {
          @extend .page-item;
          width: 30px !important;
          border-radius: 50%;
          background-color: $primary;
          color: white;
          margin: 0;
        }
      }
    }

    .page-link {
      background-color: transparent;
      transition: none;

      i {
        font-size: 12px;
      }

      &:hover,
      &:focus {
        color: inherit;
        border: none;
        box-shadow: none;
      }
    }
  }

  .entries-wrapper {
    display: flex;
    align-items: center;
    position: relative;

    p {
      margin: 0;
      padding-right: 24px;
    }

    select {
      width: 63px;
      height: 30px;
      padding-left: 8px;
      background-color: white;
      border: 1px solid #CACACA;
      border-radius: 5px;
      cursor: pointer;
      -webkit-appearance: none;
      -moz-appearance: none;
      text-indent: 1px;
      text-overflow: '';

      &:focus {
        outline: none;
      }
    }

    .icon-angel-down {
      font-size: 12px;
      position: absolute;
      top: 10px;
      right: 10px;
    }

    .entries-toggle {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 24px;
      font-size: 14px;

      .bold {
        font-weight: 700;
      }

      .toggle {
        margin: 0 14px;

        &.toggled {
          background: $gray-600;
        }

        &.toggle-small {
          @include size(32px, 16px, 2px);
        }
      }
    }
  }
}

// Sub Tables navigation
.nav-tabs {
  margin: 50px 0 40px 0;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    width: 100%;
    background: $gray-400;
  }

  .nav-item {
    position: relative;
    margin-right: 28px;

    .nav-link {
      padding: 0 0 10px 0;
      color: #000000;
      font-size: 16px;

      &.active {
        color: $primary;
        font-weight: 700;

        &::after {
          content: '';
          position: absolute;
          bottom: 0px;
          left: 0;
          height: 2px;
          width: 100%;
          background: $primary;
          z-index: 1;
        }

        .nav-link {
          color: $primary;
        }
      }
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

// Networking subnets allocation pool
.progress {
  width: 157px;
  height: 17px;
  padding: 0;
  border-radius: 0;
  position: relative;

  .progress-bar {
    color: inherit;
    background-color: lighten($primary, 33%);

    .progress-bar-value {
      p {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        padding: 0;
        margin: 0;
      }
    }
  }
}


// For instances plates
.instance-plates {
  display: grid;
  grid-template-columns: repeat(auto-fill, 340px);
  grid-gap: 30px;
//  flex-wrap: wrap;

  .instance-plates-wrapper {
//    margin: 4px 30px 30px 0;
    transition: all ease-in-out 0.2s;
    width: 340px;
    height: 100%;
//    height: 340px;
    position: relative;

    &:not(.placeholders):hover {
      box-shadow: 0 20px 20px -20px $gray-500;
      transform: translateY(-4px);
      z-index: 1000;

      &:after {
        content: '';
        position: absolute;
        height: 4px;
        width: 100%;
        background: #276EF1;
        border-radius: 5px 5px 0 0;
        left: 0;
        top: 0;
      }
    }
  }
}

.instance-plate {
  text-decoration: none;
  color: $gray-900;
  position: relative;

  .mat-mdc-card {
    background-color: white;
    border-radius: 5px;
    padding: 0;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .mat-mdc-card-header {
    display: flex;
    flex-direction: column-reverse;
    padding: 30px 30px 26px 30px;
  }

  .mat-mdc-card-header-text {
    margin: 0;

    .mat-mdc-card-title {
      margin: 30px 0 0 0;
      font-size: 22px;
      letter-spacing: 0.5px;
      line-height: 28px;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .plate-header-content {
    display: flex;
    justify-content: space-between;
    position: relative;

    svg,
    .os-placeholder {
      height: 80px;
      width: 80px;
    }
  }

  .mat-mdc-card-content {
    padding: 24px 30px 10px 30px;
    border-top: 1px solid $gray-300;

    .plate-content-item {
      padding-bottom: 10px;
      display: flex;

      .content-item-title {
        min-width: 80px;
        font-size: 12px;
        line-height: 21px;
        font-weight: 500;
        color: $gray-700;
      }

      .content-item-text {
        min-width: 80px;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.3px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }

  &:hover {
    text-decoration: none;
    color: inherit;
  }
}

.plate-header-menu {
  display: flex;
  position: absolute;
  top: 30px;
  right: 30px;
  z-index: 1;
  cursor: default;
  min-width: 80px;

  .details-buttons {
    margin-left: 10px;
  }
}

// Placeholders
.animated {
  margin: 18px 0;
  padding: 0;
  height: 20px;
  border-radius: 4px;
  background: linear-gradient(to right, #eee 20%, #ddd 50%, #eee 80%);
  background-size: 500px 100px;
  animation-name: moving-gradient;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-fill-mode: forwards;

  &-dark {
    background: linear-gradient(to right, #333 20%, #444 50%, #333 80%);
  }

  &-slow {
    animation-duration: 5s;
  }
}

@keyframes moving-gradient {
  0% { background-position: -250px 0; }
  100% { background-position: 250px 0; }
}

@-webkit-keyframes moving-gradient {
  0% { background-position: -250px 0; }
  100% { background-position: 250px 0; }
}

.custom-content {
  .renew {
    position: relative;
    width: 10px;
    height: 10px;
    background: $secondary;
    // position: absolute;
    // left: -25px;
    border-radius: 50%;
  }
}

.cdk-column-custom {
  position: absolute;
  padding-left: 0 !important;
  left: -25px;
  background: transparent !important;
  height: 100%;
}

.instance-plates {
  .custom-content {
    position: absolute;
    top: -20px;
    left: -20px;
  }
}

