.clipboard {
  position: relative;
  cursor: pointer;

  // angel on top
  // &:before {
  //   content: '';
  //   display: none;
  //   position: absolute;
  //   z-index: 9998;
  //   top: 35px;
  //   left: 15px;
  //   width: 0;
  //   height: 0;
    
  //   border-left: 5px solid transparent;
  //   border-right: 5px solid transparent;
  //   border-bottom: 5px solid rgba(0,0,0,.72);
  // }

  &:after {
    content: 'Copy';
    display: none;
    position: absolute;
    z-index: 9999;
    color: $gray-900;
    font-size: 12px;
    text-align: center;

    background: $gray-300;
    border-radius: 3px;
    left: 50%;
    transform: translateX(-50%);
    bottom: calc(100% + 10px);
    top: auto;
    line-height: 24px;
    height: auto;
    width: auto;
    padding: 0 10px;
  }
  
  &:hover {
    &:before, &:after {
      display: block;
    }
  }
  
  &.copied {
    &:after {
      content: 'Copied';
      background: $success;
      color: #ffffff;
    }
  }
}