.details {
  width: 100%;
  background-color: $white;
  border-radius: 5px;

  .details-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 35px 24px 30px 40px;

    .header-right-content,
    .header-left-content {
      display: flex;
      align-items: center;
    }

    .header-left-content {
      img {
        margin-right: 40px;
        width: 40px;
        height: auto;
      }
    }

    .header-right-content {
      justify-content: flex-end;

      .price-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }

      .animated {
        width: 260px;
      }
    }

    .price {
      font-size: 22px;
      letter-spacing: 0.5px;
      line-height: 28px;
      white-space: nowrap;
    }

    .status-widget,
    .ticket-actions {
      margin: 0 20px;
    }

    h2 {
      margin: 0;
     }
  }

  .details-content {
    height: 100%;
    border-top: 1px solid $gray-200;
    display: flex;

    &.simple {
      margin-left: 40px;
    }

    .details-left-content,
    .details-any-content,
    .details-right-content {
      flex-basis: 50%;
      padding: 25px 0 30px 40px;
    }

    .details-right-content {
      border-left: 1px solid $gray-200;
      padding-left: 40px;
      position: relative;

      overflow: visible; //Overflow must be set to visible in the detail view to prevent long tooltips (e.g. cluster details) being cut off

      &::after {
        top: 0;
        right: 0px;
        height: 90%;
        width: 20px;
        position: absolute;
        content: '';
        background: linear-gradient(90deg, rgba(255,255,255,0) 50%, rgba(255,255,255,1) 100%);
      }
    }

    .label {
      font-size: 12px;
    }

    &.instance {
      .details-left-content,
      .details-any-content,
      .details-center-content,
        .details-right-content {
        flex-basis: 33.33%;
        padding: 25px 0 30px 0;
      }

      .details-right-content,
      .details-center-content {
        border-left: 1px solid $gray-200;
        padding-left: 40px;
      }
      .details-left-content {
        padding-left: 40px;
      }
    }

    &.simple {
      border-top: none;
      padding-bottom: 30px;

      .details-content-table {
        font-size: 16px;
      }

      td {
        min-width: 220px;
        height: 40px;
      }
    }

    &.ticket {
      border-bottom: 1px solid $gray-200;
    }

    h3 {
      font-size: 16px;
      letter-spacing: 0.5px;
      line-height: 25px;
      padding-bottom: 15px;
    }
  }

  .details-content-table {
    letter-spacing: 0.5px;
    line-height: 25px;
    font-size: 14px;

    td {
      min-width: 130px;
      height: 30px;

      &.label {
        display: flex;
        align-items: center;
      }
    }
  }
}

.tab-content {
  position: relative;
}

.dropdown-menu {
  &.entities-menu {
    .menu-item {
      padding: 0;

      button {
        border: none;
        background-color: transparent;
        width: 100%;
        height: 50px;
        padding: 0 27px;
        font-size: 14px;
        letter-spacing: 0.3px;
        line-height: 18px;
        text-align: left;

        i { margin-right: 8px; }

        &:enabled {
          &:hover {
            background-color: rgba(235,239,240,0.5);
            color: $primary;
          }
        }
      }
    }
  }
}

.details-card-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  margin-top: 24px;

  .details-card {
    padding: 24px;
    justify-self: center;
    width: calc(100% - 24px);

    &:first-child {
      justify-self: start;
      width: calc(100% - 12px);
    }
    &:last-child {
      justify-self: end;
      width: calc(100% - 12px);
    }

    .details-card-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    h3 {
      font-size: 16px;
      letter-spacing: 0.5px;
      line-height: 25px;
      padding-bottom: 15px;
    }

    .details-content-table {
      letter-spacing: 0.5px;
      line-height: 25px;
      font-size: 14px;

      td {
        min-width: 130px;
        height: 30px;

        &.label {
          display: flex;
          align-items: center;
        }
      }
    }
  }
}

.details-buttons,
.menu-buttons {
  display: flex;
}
