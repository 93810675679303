@mixin public-password-reset-component($theme) {
  header {
    .header-container {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      width: 100%;
    }

    p {
      width: 100%;
      padding-top: 60px;
    }
  }

  .button-wrapper {
    .info {
      justify-content: center;
      margin-left: 30px;
    }
  }
}


