@import "styles/core/_colors.scss";

.teaser-content {
  .logo-container {
    display: none;

    margin-bottom: 52px;
  }
}

.form-container {
  display: flex;
  flex-direction: column;

  h1 {
    margin: 0 0 32px 0;

    font-size: 35px;
    line-height: 42px;
    font-weight: bold;
  }

  p {
    margin-bottom: 24px;
  }

  form {
    width: 370px;
  }

  .button-wrapper {
    display: flex;
    align-items: center;
    gap: 32px;

    a {
      color: $gray-600;
    }
  }

  .info {
    margin-top: 32px;

    span {
      display: block;

      color: $gray-600;

      font-size: 14px;
      font-weight:500;
      line-height: 21px;

      margin: 0 0 16px 0;

      &:last-child {
        margin-bottom: 0;
      }

      a {
        font-size: 14px;
        font-weight:500;
        line-height: 21px;
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .teaser-content {
    width: 100%;
    margin: 40px 20px;

    .logo-container {
      display: block;
    }
  }

  .form-container {
    flex-grow: 1;

    max-width: inherit !important;
    width: auto;

    form {
      width: auto;
    }
  }
}
