.badge {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  height: 26px;
  width: 86px;
  border-radius: 4px;
  text-transform: capitalize;
  font-weight: 700 !important;

  .badge-wrap {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &.badge-scope {
    border-radius: 38px;
    width: fit-content;
    background-color: $blue;
  }

  &.badge-succeeded,
  &.badge-success,
  &.badge-running,
  &.badge-operational,
  &.badge-paid,
  &.badge-active,
  &.badge-available,
  &.badge-healthy,
  &.badge-ready,
  &.badge-info,
  &.badge-resolved,
  &.badge-invoiced,
  &.badge-powered-on,
  &.badge-2fa-enabled {
    background-color: $success;
  }

  &.badge-failed,
  &.badge-suspended,
  &.badge-overdue,
  &.badge-error,
  &.badge-authentication-required,
  &.badge-marked-for-deletion {
    background-color: $danger;
  }

  &.badge-creating,
  &.badge-starting,
  &.badge-processing {
    background-color: #8317B9;
  }

  &.badge-unpaid,
  &.badge-shuttoff,
  &.badge-solved,
  &.badge-closed,
  &.badge-created,
  &.badge-stopped,
  &.badge-powered-off,
  &.badge-inactive,
  &.badge-unplugged,
  &.badge-un-plugged,
  &.badge-disabled,
  &.badge-2fa-disabled {
    background-color: $gray-500;
  }

  &.badge-open,
  &.badge-in-use {
    background-color: $primary;
  }

  &.badge-pending,
  &.badge-maintenance,
  &.badge-underfunded {
    background-color: #8317B9;
  }

  &.badge-trial {
    background-color: $blue;
  }

  &.badge-warn,
  &.badge-degraded {
    background-color: $warning;
  }
}
