@use "sass:math";

@mixin header-component($theme) {
  .header {
    height: 95px;
    background: #000;
    position: relative;
    display: flex;
    position: fixed;
    width: 100%;
    z-index: 1500;

    .header-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 100%;

      .header-logo {
        z-index: 110;
        display: flex;
        align-items: center;
        flex-direction: column;
        align-content: center;
        margin-left: 41px;
        min-width: 48px;

        transition: all 0.2s ease;

        svg {
          height: 25px;
          max-width: 81px;
          max-height: 25px;
          transition: all 0.2s ease;
        }

        &.small {
          margin-left: math.div(95px - 48px, 2);

          svg {
            max-width: 48px;
            max-height: 15px;
          }
        }
      }

      .user-menu {
        height: 27px;
        display: flex;
        align-items: center;
        margin-right: 40px;

        .menu-item {
          margin-right: 21px;

          &:first-child {
            // Balance margin
            margin-right: 31px;
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    .info-message {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      width: 100%;

      &:before {
        content: '';
        width: 100%;
        height: 4px;
        position: absolute;
        top: 0;
        left: 0;
      }

      &.info {
        &:before {
          background: $primary;
        }

        .message-container {
          background: $primary;

          .icon {
            font-weight: 600;
            font-size: 16px;
          }
        }
      }

      &.warning {
        &:before {
          background: $danger;
        }

        .message-container {
          background: $danger;
        }
      }

      &.impersonate {
        &:before {
          background: $secondary;
        }

        .message-container {
          background: $secondary;
        }
      }

      .message-container {
        min-width: 340px;
        display: flex;
        justify-content: space-between;
        color: #ffffff;
        padding: 18px 30px;
        border-radius: 0 0 10px 10px;

        a {
          font-family: $font-family-sans-serif-bold;
          color: #ffffff;
          text-decoration: none;
          cursor: pointer;
        }

        > div {
          margin: 0 5px;
        }
      }
    }
  }

  .trial-list {
    list-style-type: square;
    margin-left: 20px;
    margin-top: 10px;
  }
}
