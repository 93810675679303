/*********************************************************************
   BROWSER RESET
*********************************************************************/

/********************************************
   RESET ALL ELEMENTS
********************************************/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



/*********************************************************************
   HTML RESETS
*********************************************************************/
html {
  font-size: 62.5%; //reset base font size to 10px
}

body {
  min-height: 100vh;
  overscroll-behavior: none;
  // overflow: hidden; // scroll disabled
}

/*********************************************************************
   HEADING RESETS
*********************************************************************/
h1,
h2,
h3,
h4,
h5,
h6 {
  text-rendering: optimizeLegibility;
}

h1 {
  font-size: $fontSize28;
}

h2 {
  &.margin {
    margin: 3rem 0;
  }
}

/*********************************************************************
   ELEMENT RESETS
*********************************************************************/
strong {
  font-weight: 700 !important;
}

button {
  outline: none !important;
}

picture {
  width: 100%;
  height: 100%;
}

.reset-margin-padding {
  margin: 0;
  padding: 0;
}

i.icon {
  font-style: normal;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0;
  /* <-- Apparently some margin are still there even though it's hidden */
}

p {
  line-height: 1.35;
}

svg {
  vertical-align: unset;
}

a:link {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  &.nav-link:hover {
    text-decoration: none;
  }
}

ul li ul {
  padding: 0;
}

.header-menu ul {
  padding: 0;
}
