.wizard {


    .modal-header {
      height: 70px;
  
      .wizard-steps {
        .wizard-step {
          font-size: 12px;
          line-height: 24px;
          height: 23px;
          width: 23px;
          display: flex;
          border-radius: 50%;
          border: 1px solid white;
          justify-content: center;
          align-items: center;
          position: relative;
          margin-right: 30px;
  
          &.active {
            background-color: white;
            color: $primary !important;
          }
  
          &:after {
            content: '';
            display: inline-block;
            width: 30px;
            border-bottom: 1px solid white;
            position: absolute;
            left: 22px;
            top: 10px;
          }
  
          &:last-child {
            margin-right: 0;
  
            &:after {
              display: none;
            }
          }
  
        }
      }
    }
  
    .modal-body {  
      overflow-y: scroll;
      
      &::-webkit-scrollbar {
        display: none;
      }
      -ms-overflow-style: none;
      scrollbar-width: none;
      
      //Wizard
      .wizard-content {
        width: 100%;
        height: 100%;
        position: relative;
        flex-wrap: nowrap;

        &::-webkit-scrollbar {
          display: none;
        }
        
        -ms-overflow-style: none;
        scrollbar-width: none;
        
        .wizard-section {
          flex-grow: 1;
        }
  
        .wizard-section-content {
          display: flex;
          flex-direction: column;
          height: 100%;
          
          .custom-label-override {
            margin-top: auto;
            padding-left: 0;
            padding-right: 0;
            text-align: left;
          }
    
          .custom-label {
            margin: 20px;
          }
    
          .wizard-section-footer {
            margin-top: auto;
            padding-bottom: 24px;
          }
        }
      }
  
      .nav-tabs {
        margin: 50px auto 40px auto;
        width: fit-content;
        &::after {
          content: '';
          width: 100%;
        }
      }
    }
  
    .modal-footer {
      &.custom-footer {
        justify-content: flex-end;
      }
    }
}