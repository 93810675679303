@mixin header-status-component($colors) {
  .status-container {
    display: flex;
    position: relative;
  }

  .status-widget {
    display: flex;
    position: relative;
    
    &:hover {
      cursor: pointer;
    }
  }

  .status-widget__led {
    @extend .status;

    &[data-state="operational"],
    &.operational {
      @extend .status, .success;
    }

    &[data-state="degraded"],
    &.partial-outage,
    &.degraded-performance {
      @extend .status, .warning;
    }

    &[data-state="outage"],
    &.major-outage {
      @extend .status, .danger;
    }

    &.maintenance {
      @extend .status, .primary;
    }

    +.status-widget__state {
      margin-left: .5rem;
    }
  }

  .dropdown-status-menu {
    top: 26px;
    right: 0;

    &:before {
      content: '';
      position: absolute;
      top: -80px;
      left: 0;
      width: 260px;
      height: 100px;
    }

    .container {
      flex-wrap: wrap;
    }
  }

  .menu-container {
    &.action {
      margin-top: 0 !important;
      width: 100%;

      .dropdown-item {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      i {
        font-size: 20px;
      }
    }
  }

  .menu-item {
    flex-direction: column;
    align-items: flex-start !important;
  }

  .menu-section {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;

    i {
      font-size: 20px;
    }

    .label {
      color: #000000;
      font-size: 16px;
    }

    .operational {
      color: $success;
    }

    .partial-outage,
    .degraded-performance {
      color: $warning;
    }

    .major-outage {
      color: $danger;
    }

    .maintenance {
      color: $primary;
    }

    &.ongoing-incidents,
    &.planned-incidents {
      color: #000000;
      white-space: normal;
      width: calc(260px - 52px);
      margin-top: 12px;
      display: block;

      &:hover {
        color: $primary;
      }
    }

    &.ongoing-incidents {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
