@font-face {
    font-family: "icons";
    src: url("./icons.eot?86a6c51fc5c76f9e216644e218fdbc03#iefix") format("embedded-opentype"),
url("./icons.woff?86a6c51fc5c76f9e216644e218fdbc03") format("woff"),
url("./icons.woff2?86a6c51fc5c76f9e216644e218fdbc03") format("woff2");
}

i[class^="icon-"]:before, i[class*=" icon-"]:before {
    font-family: icons !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-add-button:before {
    content: "\f101";
}
.icon-add-circle-button:before {
    content: "\f102";
}
.icon-add-member:before {
    content: "\f103";
}
.icon-add:before {
    content: "\f104";
}
.icon-angel-down:before {
    content: "\f105";
}
.icon-angel-left:before {
    content: "\f106";
}
.icon-angel-right:before {
    content: "\f107";
}
.icon-angel-up:before {
    content: "\f108";
}
.icon-apple:before {
    content: "\f109";
}
.icon-army-shield:before {
    content: "\f10a";
}
.icon-assign-user:before {
    content: "\f10b";
}
.icon-attach:before {
    content: "\f10c";
}
.icon-auto-recharge-disable:before {
    content: "\f10d";
}
.icon-auto-recharge-enable:before {
    content: "\f10e";
}
.icon-auto-recharge:before {
    content: "\f10f";
}
.icon-back:before {
    content: "\f110";
}
.icon-binocular:before {
    content: "\f111";
}
.icon-button-play:before {
    content: "\f112";
}
.icon-button-refresh-arrow:before {
    content: "\f113";
}
.icon-button-stop:before {
    content: "\f114";
}
.icon-calendar-blue-30:before {
    content: "\f115";
}
.icon-calendar-blue-7:before {
    content: "\f116";
}
.icon-calendar-blue:before {
    content: "\f117";
}
.icon-calendar:before {
    content: "\f118";
}
.icon-cancel-bold:before {
    content: "\f119";
}
.icon-cancel:before {
    content: "\f11a";
}
.icon-card-view:before {
    content: "\f11b";
}
.icon-certificate:before {
    content: "\f11c";
}
.icon-change-credit-card:before {
    content: "\f11d";
}
.icon-check-circle:before {
    content: "\f11e";
}
.icon-checkbox-checked:before {
    content: "\f11f";
}
.icon-checkbox:before {
    content: "\f120";
}
.icon-ci-integration:before {
    content: "\f121";
}
.icon-ci-provider-buildkite:before {
    content: "\f122";
}
.icon-ci-provider-flow-ci:before {
    content: "\f123";
}
.icon-ci-provider-github-actions:before {
    content: "\f124";
}
.icon-ci-provider-gitlab:before {
    content: "\f125";
}
.icon-ci-provider-jenkins:before {
    content: "\f126";
}
.icon-ci-provider-team-city:before {
    content: "\f127";
}
.icon-ci-subscription:before {
    content: "\f128";
}
.icon-circle-pad-finger:before {
    content: "\f129";
}
.icon-clipboard:before {
    content: "\f12a";
}
.icon-clock:before {
    content: "\f12b";
}
.icon-cloud-image:before {
    content: "\f12c";
}
.icon-configuration-window:before {
    content: "\f12d";
}
.icon-configurations_upgrades:before {
    content: "\f12e";
}
.icon-connect:before {
    content: "\f12f";
}
.icon-cordon:before {
    content: "\f130";
}
.icon-create:before {
    content: "\f131";
}
.icon-credit-card:before {
    content: "\f132";
}
.icon-currency-dollar-blue:before {
    content: "\f133";
}
.icon-debian:before {
    content: "\f134";
}
.icon-delete-member:before {
    content: "\f135";
}
.icon-delete:before {
    content: "\f136";
}
.icon-disable-debug:before {
    content: "\f137";
}
.icon-download-file:before {
    content: "\f138";
}
.icon-download:before {
    content: "\f139";
}
.icon-drain:before {
    content: "\f13a";
}
.icon-enable-debug:before {
    content: "\f13b";
}
.icon-expand-window:before {
    content: "\f13c";
}
.icon-expand:before {
    content: "\f13d";
}
.icon-eye-off:before {
    content: "\f13e";
}
.icon-eye-on:before {
    content: "\f13f";
}
.icon-file-text-image:before {
    content: "\f140";
}
.icon-flag-swiss:before {
    content: "\f141";
}
.icon-global-network:before {
    content: "\f142";
}
.icon-health-monitor:before {
    content: "\f143";
}
.icon-hierarchy:before {
    content: "\f144";
}
.icon-impersonate:before {
    content: "\f145";
}
.icon-info-filled:before {
    content: "\f146";
}
.icon-information-circle:before {
    content: "\f147";
}
.icon-invoice:before {
    content: "\f148";
}
.icon-keyboard-arrow-left:before {
    content: "\f149";
}
.icon-keyboard-arrow-right:before {
    content: "\f14a";
}
.icon-keypair:before {
    content: "\f14b";
}
.icon-kubernetes-cluster:before {
    content: "\f14c";
}
.icon-list-view:before {
    content: "\f14d";
}
.icon-load-balancer:before {
    content: "\f14e";
}
.icon-lock:before {
    content: "\f14f";
}
.icon-mac-mini:before {
    content: "\f150";
}
.icon-mail:before {
    content: "\f151";
}
.icon-manual-recharge:before {
    content: "\f152";
}
.icon-monetization-approve:before {
    content: "\f153";
}
.icon-navigation-up-circle:before {
    content: "\f154";
}
.icon-network-server:before {
    content: "\f155";
}
.icon-notify:before {
    content: "\f156";
}
.icon-object-storage:before {
    content: "\f157";
}
.icon-os-open-filebrowser:before {
    content: "\f158";
}
.icon-out-right:before {
    content: "\f159";
}
.icon-password-lock:before {
    content: "\f15a";
}
.icon-pencil:before {
    content: "\f15b";
}
.icon-phone-charger:before {
    content: "\f15c";
}
.icon-plus-thin:before {
    content: "\f15d";
}
.icon-plus:before {
    content: "\f15e";
}
.icon-prod-minus:before {
    content: "\f15f";
}
.icon-prod-plus:before {
    content: "\f160";
}
.icon-public-network:before {
    content: "\f161";
}
.icon-question-circle-filled:before {
    content: "\f162";
}
.icon-question-circle:before {
    content: "\f163";
}
.icon-rebuild:before {
    content: "\f164";
}
.icon-resize:before {
    content: "\f165";
}
.icon-revert:before {
    content: "\f166";
}
.icon-router:before {
    content: "\f167";
}
.icon-search-bold:before {
    content: "\f168";
}
.icon-search:before {
    content: "\f169";
}
.icon-security-group:before {
    content: "\f16a";
}
.icon-security-lock-2:before {
    content: "\f16b";
}
.icon-server:before {
    content: "\f16c";
}
.icon-status-arrow:before {
    content: "\f16d";
}
.icon-status-degraded-performance:before {
    content: "\f16e";
}
.icon-status-maintenance:before {
    content: "\f16f";
}
.icon-status-major-outage:before {
    content: "\f170";
}
.icon-status-operational:before {
    content: "\f171";
}
.icon-status-partial-outage:before {
    content: "\f172";
}
.icon-synchronize-arrows-triangle:before {
    content: "\f173";
}
.icon-three-dots:before {
    content: "\f174";
}
.icon-tick:before {
    content: "\f175";
}
.icon-transition:before {
    content: "\f176";
}
.icon-trash:before {
    content: "\f177";
}
.icon-ubuntu:before {
    content: "\f178";
}
.icon-uncordon:before {
    content: "\f179";
}
.icon-unlock:before {
    content: "\f17a";
}
.icon-user:before {
    content: "\f17b";
}
.icon-volume:before {
    content: "\f17c";
}
.icon-vpn-connection:before {
    content: "\f17d";
}
.icon-workflows:before {
    content: "\f17e";
}
.icon-wrench:before {
    content: "\f17f";
}
