.dropdown-menu {
  width: auto;
  min-width: 260px;
  margin-top: 16px;
  box-shadow: 0 0 20px 0 rgba(0,0,0,0.25);
  border-radius: 5px;
  padding: 0;
  z-index: 5000;

  .menu-item {
    display: flex;
    justify-content: space-between;
    align-items: center;

    position: relative;

    padding: 26px;
    border-bottom: 1px solid $gray-200;
    margin-right: 0 !important;
    white-space: nowrap;

    .menu-sub-menu {
      display: flex;
      flex-direction: column;

      position: absolute;
      visibility: hidden;

      top: 0;
      right: 100%;

      width: auto;
      min-width: 200px;

      box-shadow: 0 0 20px 0 rgba(0,0,0,0.25);
      border-radius: 5px;

      background-color: #fff;
    }

    &:last-child {
      border-bottom: none;
    }

    .label {
      @extend .label;
    }

    .text-right {
      font-size: 16px;
    }

    .menu-container {
      display: flex;
      flex-direction: column;
      flex-basis: 100%;
      margin-top: 12px;

      .dropdown-item {
        padding: 0;

        &:hover {
          color: $primary;
        }
      }
    }

    button {
      display: flex;
      align-items: center;
    }
  }

  button {
    background-color: transparent;
  }
}

.dropdown-menu .menu-item:hover .menu-sub-menu {
  visibility: visible;
}
