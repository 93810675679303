/*********************************************************************
  BUTTONS STYLES
*********************************************************************/

*[routerLink] {
  outline: none;
  cursor: pointer;
}

.btn {
  cursor: pointer;
  border: none;
  border-radius: $btn-primary-radius;
  text-transform: uppercase;

  .icon {
    margin-top: 1px;
    float: left;

    svg {
      width: 13px;
      height: 13px;
    }

    &:first-child { margin-right: 0.5rem; }
    &:last-child {
      margin-left: 0.5rem;
      float: right;
    }
  }

  &.btn-delete {
    @extend .btn-danger;
  }

  &.btn-active {
    padding: 0;
    margin: 0;
    color: $danger;
    display: block;

    &:focus {
      box-shadow: none;
    }
  }

  &.btn-big {
    height: 4.8rem;
  }
  &.btn-wide {
    min-width: 15rem;
  }

  &.btn-back {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .2s;
    min-width: 10rem;

    &:hover {
      color: $primary;
    }

    .icon {
      float: none;
      display: flex;
    }
  }

  &.btn-link {
    text-transform: capitalize;
    text-decoration: unset;
    color: $primary;

    &:hover {
      color: $light;
    }
  }

  &.btn-outline {
    letter-spacing: 0.75px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-color: $primary;
    border: 1px solid $primary;
    color: $primary;

    &:hover {
      background-color: $primary;
      color: white;
    }
  }

  &.btn-primary {
    letter-spacing: 0.3px;
    display: flex;
    justify-content: center;
    align-items: center;

    @include button-variant(
        $primary,
        $primary,
        $white,
        $primary-dark,
        $primary-dark,
        $white,
        $primary-dark,
        $primary-dark
        $white,
    );

    &.disabled {
      background-color: lighten($primary, 20%);
    }

    .icon-assign-user {
      margin-right: 8px;
      font-size: 16px;
      padding-top: 1px;
    }

    .icon-add {
      margin-right: 8px;
      font-size: 12px;
    }
  }
}

.back-link, .btn-small {
  max-height: 26px;
  padding: 0.3rem 2rem;
  display: flex;
  align-items: center;
  font-weight: 700;

  &.btn-primary {
    color: $white;
  }
}

.back-link.btn-back {
  padding: 0.3rem 1.1rem;
  margin: 0px 40px;
  min-width: 0;

  i {
    margin: 0 !important;
    font-size: 20px;
    line-height: 19px;
  }
}

.dotted-menu {
  @extend .back-link;
  padding: 0.1rem 1rem;

  &.disabled {
    background-color: $gray-500 !important;
  }

  &:after { display: none; }

  i { font-size: 22px; }
}

.dropup .dropdown-toggle::after {
  display: none;
}

.dropdown-menu {
  &.entities-menu {
    min-width: 200px;

    .menu-item {
      padding: 0;

      button,
      .button {
        border: none;
        background-color: transparent;
        width: 100%;
        height: 50px;
        padding: 0 27px;
        font-size: 14px;
        letter-spacing: 0.3px;
        line-height: 18px;
        text-align: left;

        i { margin-right: 8px; }

        &:enabled {
          &:hover {
            background-color: rgba(235,239,240,0.5);
            color: $primary;
          }
        }
      }

      .button {
        text-decoration: none;
        color: $gray-900;
        display: flex;
        align-items: center;
      }
    }

    .delete-menu-item button {
      color: $danger;

      &:enabled {
        &:hover {
          color: $danger !important;

          i, span {
            color: $danger;
          }
        }
      }

      &:disabled {
        color: lighten($danger, 10%);
      }
    }
  }
}

.details-buttons,
.menu-buttons {
  display: flex;
}

.btn-container { // TODO replace absolute position with custom tabbed component
  position: relative;

  &.list-view {
    position: fixed;
    right: 17px;
    top: 190px;
    margin: 40px 45px;
    display: flex;
    z-index: 1100;


    .dotted-menu {
      padding: 0.6rem 1.45rem;
      display: flex;
      justify-content: center;
      align-items: center;

      i {
        font-size: 13px;
        line-height: 12px;
      }
    }
  }

  &.sub-list-view {
    display: flex;

    position: absolute;
    right: 0px;
    top: -75px;

    margin: 0 25px;

    .dotted-menu {
      padding: 0.6rem 1.45rem;
      display: flex;
      justify-content: center;
      align-items: center;

      i {
        font-size: 13px;
        line-height: 12px;
      }
    }
  }

  .btn,
  .wrapper {
    // position: absolute;
    // right: 0;
    // top: -80px;
  }

  .search {
    position: relative;
    // position: absolute;
    // right: 0;
    // top: -80px;

    .btn {
      transition: padding 0.4s ease;
      position: relative;
      right: 0;
      top: 0;
      z-index: 4;
      min-width: 26px;
      min-height: 26px;

      &.round {
        padding: 0.6rem;
      }

      .icon-cancel {
        font-size: 10px;
      }
    }

    input[type="text"] {
      opacity: 0;
      height: 26px;
      font-size: 12px;
      border: 1px $primary solid;
      border-radius: 25px;
      padding: 5px 30px 5px 12px;
      width: 0px;
      position: absolute;
      outline: none;
      top: 0;
      right: 0;
      background: #fff;
      z-index: 3;
      -webkit-transition: width 0.4s cubic-bezier(0, 0.795, 0, 1);
      transition: width 0.4s cubic-bezier(0, 0.795, 0, 1);
      cursor: pointer;
    }

    input.active {
      opacity: 1;
      width: 250px;
      z-index: 1;
      cursor: text;
    }
  }
}

a {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }

  &:link:hover {
    text-decoration: none;
  }
}

.btn.btn-primary:focus {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}
