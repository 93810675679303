.label {
  color: $gray-600;
}

.font-500 {
  font-weight: 500;
}

.text-link {
  color: $primary;

  &:hover {
    color: darken($primary, 15);
    cursor: pointer;
  }
}